import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MatchView } from '../../types/MatchView';
import { getMatchesByDateRange } from '../../services/MatchService';
import './MatchList.css';
import { translateRankingType } from '../../helpers/translateRankingType';

interface MatchListProps {
    from: string;
    to: string;
    playerId?: number;
}

const MatchList: React.FC<MatchListProps> = ({ from, to, playerId }) => {
    const [matches, setMatches] = useState<MatchView[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchMatches = async () => {
            try {
                const matchesData = await getMatchesByDateRange(from, to, playerId);
                setMatches(matchesData);
            } catch (error) {
                setError('Error al obtener los partidos');
            } finally {
                setLoading(false);
            }
        };

        fetchMatches();
    }, [from, to, playerId]);

    if (loading) return <p>Cargando...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="match-grid">
            {matches.length === 0 ? (
                <p>No se encontraron partidos.</p>
            ) : (
                matches.map((match) => (
                    <div key={match.id} className="match-box">
                        <div className="match-details">
                            <div className="match-list-player-box-info">
                                <Link to={`/players/${match.player1.id}`}>
                                    <strong>{match.player1.firstName + ' ' + match.player1.lastName}</strong>
                                </Link>
                                <p>Ranking Absoluto: <strong>{match.player1.absoluteOrdinalRanking ?? 'N/A'}</strong></p>
                                <p><strong> </strong></p>
                                <p>Nivel: <strong>{match.player1.dynamicLevel.toFixed(2)}</strong></p>
                                <p>Confiabilidad:
                                    <strong>
                                        {match.player1.rankingReliability != null ? ` ${(match.player1.rankingReliability * 100).toFixed(0)}%` : 'N/A'}
                                    </strong>
                                </p>
                            </div>
                            <div className="match-list-player-box-info">
                                <Link to={`/players/${match.player2.id}`}>
                                    <strong>{match.player2.firstName + ' ' + match.player2.lastName}</strong>
                                </Link>
                                <p>Ranking Absoluto: <strong>{match.player2.absoluteOrdinalRanking ?? 'N/A'}</strong></p>
                                <p>Nivel: <strong>{match.player2.dynamicLevel.toFixed(2)}</strong></p>
                                <p>Confiabilidad:
                                    <strong>
                                        {match.player2.rankingReliability != null ? ` ${(match.player2.rankingReliability * 100).toFixed(0)}%` : 'N/A'}
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div className='challenge-info-container icon-small mt-3'>
                            <img src="/icons/podium.png" alt="Whats Icon" />
                            {match.ordinalRankingType && <p><strong>{translateRankingType(match.ordinalRankingType)}</strong></p>}
                        </div>
                        <div className='challenge-info-container icon-small'>
                            <img src="/icons/calendar.png" alt="Whats Icon" />
                            <p><strong>{match.matchDate}</strong></p>
                        </div>
                        {match.result && match.result.sets.length > 0 && (
                            <>
                                <div className="match-result">
                                    {match.result.walkover ? (
                                        <p><strong>Resultado:</strong> W/O</p>
                                    ) : match.result.abandoned ? (
                                        <p><strong>Resultado:</strong> Partido abandonado</p>
                                    ) : (
                                        <>
                                            <p><strong>Sets:</strong> {match.result.sets.map(set => `${set.score}`).join(', ')}</p>
                                            <p><strong>Ganador:</strong> {match.winner ? match.winner.firstName + ' ' + match.winner.lastName : 'Ninguno'}</p>
                                        </>
                                    )}
                                </div>
                                <div className="match-confirmation">
                                    {match.isConfirmed ? <img src="/icons/accept.png" alt="Confirmado" /> : <img src="/icons/time.png" alt="ConfirmacionPendiente" />}
                                    <p>Resultado {match.isConfirmed ? 'confirmado' : 'esperando confirmacion'}</p>
                                </div>
                            </>
                        )}

                    </div>
                ))
            )}
        </div>
    );
};

export default MatchList;
