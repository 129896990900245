import React from 'react';
import { Link } from 'react-router-dom';

const Admin: React.FC = () => {
    return (
        <div className="admin-container">
            <h1 className="text-2xl font-bold mb-4">Panel de Administración</h1>
            <ul className="space-y-2">
                <li>
                    <Link
                        to="/tennisadmin/challenges"
                        className="inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                    >
                        Ver Desafíos
                    </Link>
                </li>
                <li>
                    <Link
                        to="/tennisadmin/matches"
                        className="inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                    >
                        Ver Partidos a confirmar
                    </Link>
                </li>
                <li>
                    <Link
                        to="/tennisadmin/challenge-form"
                        className="inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                    >
                        Crear Desafío
                    </Link>
                </li>
                <li>
                    <a
                        href="https://tenis-app-backend.onrender.com/api/players/download?rankType=absoluteranking"
                        className="inline-block px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Descargar Ranking Absoluto
                    </a>
                </li>
                <li>
                    <a
                        href="https://tenis-app-backend.onrender.com/api/players/download?rankType=age45ranking"
                        className="inline-block px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Descargar Ranking +45
                    </a>
                </li>
                <li>
                    <a
                        href="https://tenis-app-backend.onrender.com/api/players/download/?rankType=age55ranking"
                        className="inline-block px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Descargar Ranking +55
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Admin;
