import React, { useEffect, useState } from 'react';
import { getRankingPermutationsByPlayerId } from '../../services/RankingPermutationService';
import { useParams } from 'react-router-dom';
import './LevelRankingChart.css';
import { Chart } from "react-google-charts";
import { RankingPermutation } from '../../types/RankingPermution';

const LevelRankingChart: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [levelDataset, setLevelDataset] = useState<any[][]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const isValidDate = (dateString: string | Date) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  useEffect(() => {
    const fetchRankingChanges = async () => {
      try {
        if (id) {
          const today = new Date();
          const sixMonthsAgo = new Date();
          sixMonthsAgo.setMonth(today.getMonth() - 6);
          // the date should be six months ago or the 13 of October 2024, whichever comes first
          const firstDate = new Date(2024, 9, 1);
          const dateToUse = sixMonthsAgo < firstDate ? firstDate : sixMonthsAgo;

          const changes: RankingPermutation[] = await getRankingPermutationsByPlayerId(parseInt(id), dateToUse, today);
          // Order changes by CreatedAt
          changes.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

          // Filter by ranking type
          const levelChanges = changes.filter(change => change.rankingType === 'dynamicLevel');

          // Check if the date is valid before converting to Date objects
          const levelChangesDates = levelChanges.map(change => isValidDate(change.createdAt) ? new Date(change.createdAt) : new Date());
          const levelRankings = levelChanges.map(change => parseFloat(change.newDynamicRanking.toFixed(2)));
          let levelPreDataset: any[][] = [];

          if (levelChanges.length > 0) {
            levelPreDataset.push(['Fecha', 'Nivel', { role: 'annotation' }]);
            levelPreDataset.push([dateToUse, parseFloat(levelChanges[0].previousDynamicRanking.toFixed(2)), levelChanges[0].previousDynamicRanking.toFixed(2).toString()]);
            for (let index = 0; index < levelChanges.length; index++) {
              levelPreDataset.push([levelChangesDates[index], levelRankings[index], levelRankings[index].toString()]);
            }
          }
          setLevelDataset(levelPreDataset);
        }
      } catch (err) {
        setError('Error al obtener los cambios de ranking');
      } finally {
        setLoading(false);
      }
    };

    fetchRankingChanges();
  }, [id]);

  if (loading) return <p>Cargando gráfico...</p>;
  if (error) return <p>{error}</p>;

  const options = {
    curveType: "function",
    legend: { position: "bottom" },
    lineWidth: 3,
    colors: ["red"],
    hAxis: {
      format: "d/M/yy",
      gridlines: { count: 4 },
    },
    vAxis: {
      title: "Nivel",
      direction: 1,
      gridlines: { count: 4 },
    },
    series: {
      0: {
        annotations: {
          style: 'line',
          textStyle: {
            fontSize: 16,
            bold: true,
            color: 'red',
            auraColor: 'white',
          },
        },
      },
    },
  };

  if (levelDataset.length === 0) return <></>;

  return (
    <div className="player-chart-rankings-container">
      <h2 className="heading-secondary">Nivel en los últimos 6 meses</h2>
      {/* //Render charts only if there is data */}
      {levelDataset.length > 0 &&
        <div className="chart-container">
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={levelDataset}
            options={options}
          />
        </div>
      }
    </div>
  );
};

export default LevelRankingChart;
