import React, { useEffect, useState } from 'react';
import { getRankingPermutationsByPlayerId } from '../../services/RankingPermutationService';
import { useParams } from 'react-router-dom';
import './RankingChart.css';
import { Chart } from "react-google-charts";
import { RankingPermutation } from '../../types/RankingPermution';

const RankingChart: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [absoluteDataset, setabsoluteDataset] = useState<any[][]>([]);
  const [plus45Dataset, setPlus45Dataset] = useState<any[][]>([]);
  const [plus55Dataset, setPlus55Dataset] = useState<any[][]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const isValidDate = (dateString: string | Date) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  useEffect(() => {
    const fetchRankingChanges = async () => {
      try {
        if (id) {
          const today = new Date();
          const sixMonthsAgo = new Date();
          sixMonthsAgo.setMonth(today.getMonth() - 6);
          // the date should be six months ago or the 13 of October 2024, whichever comes first
          const firstDate = new Date(2024, 9, 1);
          const dateToUse = sixMonthsAgo < firstDate ? firstDate : sixMonthsAgo;

          const changes: RankingPermutation[] = await getRankingPermutationsByPlayerId(parseInt(id), dateToUse, today);
          // Order changes by CreatedAt
          changes.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

          // Filter by ranking type
          const absolutoChanges = changes.filter(change => change.rankingType === 'absoluteOrdinalRanking');
          const plus45Changes = changes.filter(change => change.rankingType === 'age45OrdinalRanking');
          const plus55Changes = changes.filter(change => change.rankingType === 'age55OrdinalRanking');

          // Check if the date is valid before converting to Date objects
          const absolutoChangesDates = absolutoChanges.map(change => isValidDate(change.createdAt) ? new Date(change.createdAt) : new Date());
          const plus45ChangesDates = plus45Changes.map(change => isValidDate(change.createdAt) ? new Date(change.createdAt) : new Date());
          const plus55ChangesDates = plus55Changes.map(change => isValidDate(change.createdAt) ? new Date(change.createdAt) : new Date());

          const absolutoRankings = absolutoChanges.map(change => change.newRank);
          const plus45Rankings = plus45Changes.map(change => change.newRank);
          const plus55Rankings = plus55Changes.map(change => change.newRank);

          let absolutePreDataset: any[][] = [];
          let plus45PreDataset: any[][] = [];
          let plus55PreDataset: any[][] = [];

          if (absolutoChanges.length > 0) {
            absolutePreDataset.push(['Fecha', 'Absoluto', { role: 'annotation' }]);
            absolutePreDataset.push([dateToUse, absolutoChanges[0].previousRank, absolutoChanges[0].previousRank.toString()]);
            for (let index = 0; index < absolutoChanges.length; index++) {
              absolutePreDataset.push([absolutoChangesDates[index], absolutoRankings[index], absolutoRankings[index].toString()]);
            }
          }
          if (plus45Changes.length > 0) {
            plus45PreDataset.push(['Fecha', '+45']);
            plus45PreDataset.push([dateToUse, plus45Changes[0].previousRank]);
            for (let index = 0; index < plus45Changes.length; index++) {
              plus45PreDataset.push([plus45ChangesDates[index], plus45Rankings[index]]);
            }
          }
          if (plus55Changes.length > 0) {
            plus55PreDataset.push(['Fecha', '+55']);
            plus55PreDataset.push([dateToUse, plus55Changes[0].previousRank]);
            for (let index = 0; index < plus55Changes.length; index++) {
              plus55PreDataset.push([plus55ChangesDates[index], plus55Rankings[index]]);
            }
          }
          setabsoluteDataset(absolutePreDataset);
          setPlus45Dataset(plus45PreDataset);
          setPlus55Dataset(plus45PreDataset);
        }
      } catch (err) {
        setError('Error al obtener los cambios de ranking');
      } finally {
        setLoading(false);
      }
    };

    fetchRankingChanges();
  }, [id]);

  if (loading) return <p>Cargando gráfico...</p>;
  if (error) return <p>{error}</p>;

  const options = {
    curveType: "function",
    legend: { position: "bottom" },
    lineWidth: 3,
    colors: ["green"],
    hAxis: {
      format: "d/M/yy",
      gridlines: { count: 6 },
      
    },
    vAxis: {
      title: "Ranking",
      format: "0",
      direction: -1,
      gridlines: { count: 3 },
    },
    series: {
      0: {
        annotations: {
          style: 'line',
          textStyle: {
            fontSize: 16,
            bold: true,
            color: 'green',
            auraColor: 'white',
          }
        },
      },
    },
  };

  if (absoluteDataset.length === 0 && plus45Dataset.length === 0 && plus55Dataset.length === 0) return <></>;

  return (
    <div className="player-chart-rankings-container">
      <h2 className="heading-secondary">Ranking en los últimos 6 meses</h2>
      {/* //Render charts only if there is data */}
      {absoluteDataset.length > 0 &&
        <div className="chart-container">
          <h3>Ranking Absoluto</h3>
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={absoluteDataset}
            options={options}
          />
        </div>
      }

      {plus45Dataset.length > 0 &&
        <div className="chart-container">
          <h3>Ranking +45</h3>
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={plus45Dataset}
            options={options}
          />
        </div>
      }

      {plus55Dataset.length > 0 &&
        <div className="chart-container">
          <h3>Ranking +55</h3>
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={plus55Dataset}
            options={options}
          />
        </div>
      }
    </div>
  );
};

export default RankingChart;
