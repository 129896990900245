export const isDateBeforeToday = (date: Date): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
}

export const isDateBeforeOrEqualtoToday = (date: Date): boolean => {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return date <= today;
}

export const getDates = () => {
    const todayDate = new Date();
    const today = todayDate.toISOString().split('T')[0];
    const tonextYear = new Date(todayDate);
    tonextYear.setDate(todayDate.getDate() + 365);
    const nextYear = tonextYear.toISOString().split('T')[0];

    const toTomorrow = new Date(todayDate);
    toTomorrow.setDate(todayDate.getDate() + 1);
    const tomorrow = toTomorrow.toISOString().split('T')[0];

    const fromLastYear = new Date(todayDate);
    fromLastYear.setDate(todayDate.getDate() - 365);
    const lastYear = fromLastYear.toISOString().split('T')[0];

    const fromYesterday = new Date(todayDate);
    fromYesterday.setDate(todayDate.getDate() - 1);
    const yesterday = fromYesterday.toISOString().split('T')[0];

    const fromLastWeek = new Date(todayDate);
    fromLastWeek.setDate(todayDate.getDate() - 7);
    const lastWeek = fromLastWeek.toISOString().split('T')[0];

    //Next week
    const fromNextWeek = new Date(todayDate);
    fromNextWeek.setDate(todayDate.getDate() + 7);
    const nextWeek = fromNextWeek.toISOString().split('T')[0];


    return { nextYear, tomorrow, today, lastYear, yesterday, lastWeek, nextWeek };
};
