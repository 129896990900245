import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { canBeChallenged, getPlayerById, updatePlayer } from '../../services/PlayerService';
import { Player } from '../../types/Player';
import { useAuth } from '../../context/AuthContext';
import './PlayerDetails.css';
import ChallengeList from '../ChallengeList/ChallengeList';
import MatchList from '../MatchList/MatchList';
import CreateChallengeButton from '../Buttons/CreateChallengeButton/CreateChallengeButton';
import { changePassword } from '../../services/UserService';
import { getDates } from '../../helpers/timeHelpers';
import RankingChart from '../RankingChart/RankingChart';
import { useMediaQuery } from 'react-responsive';
import LevelRankingChart from '../LevelRankingChart/LevelRankingChart';

const PlayerDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [player, setPlayer] = useState<Player | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editablePhone, setEditablePhone] = useState<string | undefined>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPasswordChange, setShowPasswordChange] = useState<boolean>(false);
  const { isAuthenticated, playerAuth, userAuth } = useAuth();
  const { nextYear, tomorrow, today, lastYear } = getDates();
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [userCanBeChallenged, setUserCanBeChallenged] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [shouldRenderPendingChallenges, setShouldRenderPendingChallenges] = useState<boolean>(true);
  const [shouldRenderLoadPendingChallenges, setShouldRenderLoadPendingChallenges] = useState<boolean>(true);
  const [shouldRenderMyNextChallenges, setShouldRenderMyNextChallenges] = useState<boolean>(true);

  useEffect(() => {
    const loadPlayerDetails = async () => {
      try {
        if (id) {
          const playerData = await getPlayerById(parseInt(id));
          setPlayer(playerData);
        }
      } catch (err) {
        setError('Error al obtener los detalles del jugador');
      } finally {
        setLoading(false);
      }
    };

    loadPlayerDetails();
  }, [id]);

  useEffect(() => {
    if (playerAuth && player) {
      userChallengeable(playerAuth.id, player.id);
    }
  }, [playerAuth, player]);

  const userChallengeable = async (player1Id: number, player2Id: number) => {
    const challengeable = await canBeChallenged(player1Id, player2Id);
    setUserCanBeChallenged(challengeable);
  }

  const handleSavePhone = async () => {
    if (player && editablePhone !== undefined) {
      try {
        const updatedPlayer = await updatePlayer(player.id, { phone: editablePhone });
        setPlayer(updatedPlayer);
        setIsEditing(false);
      } catch (err) {
        setError('Error al actualizar el teléfono');
      }
    }
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditablePhone(event.target.value);
  };

  const handleChangePassword = async () => {
    if (newPassword && newPassword === confirmPassword) {
      try {
        if (!userAuth) throw new Error('Usuario no autenticado');
        if (currentPassword === '') {
          setError('Debes ingresar la contraseña actual');
          throw new Error('Ingrese la contraseña actual');
        }

        await changePassword(userAuth.id, currentPassword, newPassword);
        alert('Contraseña actualizada exitosamente');
        setNewPassword('');
        setConfirmPassword('');
        setCurrentPassword('');
        setShowPasswordChange(false);
        navigate('/');
      } catch (err) {
        setError('Error al actualizar la contraseña');
      }
    } else {
      setError('Las contraseñas no coinciden');
    }
  };

  const togglePasswordChangeSection = () => {
    setShowPasswordChange(!showPasswordChange);
  };

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;
  if (!player) return <p>No se encontró el jugador</p>;

  return (
    <div className="player-details-container">
      <h2 className="heading center">{player.firstName} {player.lastName}</h2>
      {isAuthenticated && playerAuth && playerAuth.id !== player.id && userCanBeChallenged && (
        <CreateChallengeButton player={player} isAuthenticated={isAuthenticated} />
      )}
      <div className="player-info">
        <div className="player-column telephone-column">
          <h3 className="subheading">Teléfono</h3>
          {isEditing ? (
            <div className="editable-phone">
              <input
                type="text"
                value={editablePhone}
                onChange={handlePhoneChange}
                className="phone-input"
              />
              <button
                onClick={handleSavePhone}
                className="save-button"
                disabled={!editablePhone || editablePhone.trim() === ""}
              >
                Guardar
              </button>
            </div>
          ) : (
            <div className="phone-container">
              <p className="player-info-text text">{player.phone || 'N/A'}</p>
              {isAuthenticated && playerAuth && playerAuth.id === player.id && (
                <div className='edit-phone-container'>
                  <img onClick={() => setIsEditing(true)} src="/icons/edit.png" alt="Whats Icon" />
                </div>

              )}
            </div>
          )}
        </div>
      </div>

      {isAuthenticated && playerAuth && playerAuth.id === player.id && (
        <>
          <div className="change-password-container">
            <button onClick={togglePasswordChangeSection} className="change-password-button toggle-password">
              Cambiar contraseña
            </button>

            {showPasswordChange && (
              <>
                <input
                  type="password"
                  placeholder="Contraseña actual"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="password-input"
                />
                <input
                  type="password"
                  placeholder="Nueva contraseña"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="password-input"
                />
                <input
                  type="password"
                  placeholder="Confirmar nueva contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="password-input"
                />
                <button onClick={handleChangePassword} className="save-button save-password-button">
                  Guardar Contraseña
                </button>
              </>
            )}
          </div>
        </>
      )}

      <h2 className="heading-secondary">Nivel</h2>
      <div className="player-level">
        <div className="player-column">
          <h3 className="subheading center">Nivel (0-20)</h3>
          <p className="text center">{player.dynamicLevel.toFixed(2)}</p>
        </div>
        <div className="player-column">
          <h3 className="subheading center">Confiabilidad del nivel</h3>
          <p className="text center">{player.rankingReliability !== undefined ? `${(player.rankingReliability * 100).toFixed(0)}%` : '-'}</p>
        </div>
      </div>

      <h2 className="heading-secondary">Rankings</h2>
      <div className="player-rankings">
        {player.absoluteOrdinalRanking !== null && (
          <div className="ranking-column">
            <h3 className="subheading">Ranking Absoluto</h3>
            <p className="text-ranking">{player.absoluteOrdinalRanking !== null ? player.absoluteOrdinalRanking : '-'}</p>
          </div>
        )}
        {player.age45OrdinalRanking !== null && (
          <div className="ranking-column">
            <h3 className="subheading">Ranking Edad +45</h3>
            <p className="text-ranking">{player.age45OrdinalRanking !== null ? player.age45OrdinalRanking : '-'}</p>
          </div>
        )}
        {player.age55OrdinalRanking !== null && (
          <div className="ranking-column">
            <h3 className="subheading">Ranking Edad +55</h3>
            <p className="text-ranking">{player.age55OrdinalRanking !== null ? player.age55OrdinalRanking : '-'}</p>
          </div>

        )}
      </div>
      {
        !isMobile && (
          <RankingChart />
        )
      }

      {
        !isMobile && (
          <LevelRankingChart />
        )
      }

      {isAuthenticated && playerAuth && playerAuth.id === player.id && shouldRenderLoadPendingChallenges && (
        <>
          <h2 className="heading-secondary">Carga el resultado</h2>
          <div className="player-challenges">
            <ChallengeList status={"accepted"} from={lastYear} to={today} playerId={player.id} setShouldRender={setShouldRenderLoadPendingChallenges} />
          </div>
        </>
      )}
      {shouldRenderMyNextChallenges && (
        <>
          <h2 className="heading-secondary">Próximos Desafíos de {player.firstName}</h2>
          <div className="player-challenges">
            <ChallengeList status={"accepted"} from={today} to={nextYear} playerId={player.id} setShouldRender={setShouldRenderMyNextChallenges} />
          </div>
        </>
      )}
      {isAuthenticated && playerAuth && playerAuth.id === player.id && shouldRenderPendingChallenges && (
        <>
          <h2 className="heading-secondary">Desafíos Pendientes de respuesta</h2>
          <div className="player-challenges">
            <ChallengeList status={"pending"} from={lastYear} to={nextYear} playerId={player.id} setShouldRender={setShouldRenderPendingChallenges} />
          </div>
        </>
      )}

      <h2 className="heading-secondary">Últimos Resultados de {player.firstName}</h2>
      <div className="player-matches">
        <MatchList from={lastYear} to={tomorrow} playerId={player.id} />
      </div>
    </div>
  );
};

export default PlayerDetails;
