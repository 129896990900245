import React, { useEffect, useState } from 'react';
import ChallengeList from '../../components/ChallengeList/ChallengeList';
import './Challenges.css';
import { useAuth } from '../../context/AuthContext';
import { checkPendingChallengesWithAction } from '../../services/ChallengeService';
import { getDates } from '../../helpers/timeHelpers';

const Challenges: React.FC = () => {
  const { playerAuth } = useAuth();
  const { nextYear, tomorrow, today, lastYear, lastWeek } = getDates();
  const [hasPendingChallenges, setPendingChallenges] = useState<boolean>(false);
  const [shouldRenderPendingChallenges, setShouldRenderPendingChallenges] = useState<boolean>(true);
  const [shouldRenderLoadPendingChallenges, setShouldRenderLoadPendingChallenges] = useState<boolean>(true);
  const [shouldRenderMyNextChallenges, setShouldRenderMyNextChallenges] = useState<boolean>(true);
  const [shouldRenderPlayedChallenges, setShouldRenderPlayedChallenges] = useState<boolean>(true);

  // Fetch pending challenges for the authenticated user
  useEffect(() => {
    if (playerAuth && playerAuth.id) {
      checkPendingChallengesWithAction(playerAuth.id)
        .then((hasPendingChallenge) => {
          setPendingChallenges(hasPendingChallenge);
        })
        .catch((error) => {
          console.error('Error fetching pending challenges:', error);
        });
    }
  }, [playerAuth]);

  return (
    <div className="challenges-container">
      {/* Desafios Pendiente de cargar */}
      {playerAuth && shouldRenderLoadPendingChallenges && (
        <>
          <h1 className="section-title">Pendientes de Carga</h1>
          <div className="player-challenges">
            <ChallengeList status={"accepted"} from={lastYear} to={today} playerId={playerAuth.id} setShouldRender={setShouldRenderLoadPendingChallenges} />
          </div>
        </>
      )}

      {/* Desafios pendientes de respuesta */}
      {playerAuth && hasPendingChallenges && playerAuth.id && shouldRenderPendingChallenges && (
        <div className="challenge-list">
          <h1>Pendientes de respuesta</h1>
          <ChallengeList status="pending" from={today} to={nextYear} playerId={playerAuth.id} setShouldRender={setShouldRenderPendingChallenges} />
        </div>
      )}
      {shouldRenderMyNextChallenges && (
        <div className="challenge-list">
          <h1>Proximos desafios</h1>
          <ChallengeList status="accepted" from={today} to={nextYear} setShouldRender={setShouldRenderMyNextChallenges} />
        </div>
      )}
      {shouldRenderPlayedChallenges && (
        <div className="challenge-list">
          <h1>Últimos resultados</h1>
          <ChallengeList status="played" from={lastWeek} to={tomorrow} setShouldRender={setShouldRenderPlayedChallenges} />
        </div>
      )}
    </div>
  );
};

export default Challenges;