import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getOrdinalRankings } from '../../services/RankingService';
import { translateRankingType, translateRankingTypeRaw } from '../../helpers/translateRankingType';
import { RankType } from '../../types/RankType';
import { OrdinalRankingView } from '../../types/BaseRankingView';
import './RankingTable.css';
import { useAuth } from '../../context/AuthContext';
import { getOtherRankTypes } from '../../helpers/rankHelpers';
import { useMediaQuery } from 'react-responsive';

interface RankingTableProps {
  rankType: RankType;
}

const RankingTable: React.FC<RankingTableProps> = ({ rankType }) => {
  const [rankings, setRankings] = useState<OrdinalRankingView[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { playerAuth } = useAuth();
  const pageSize = 10;
  const otherRankTypes = getOtherRankTypes(rankType);

  // Calculate the initial page based on playerAuth or default to the first page
  const rankValue = playerAuth?.[rankType] ?? 1;
  const initialPage = Math.floor((rankValue - 1) / pageSize) + 1;

  const loadRankings = useCallback(async (page: number) => {
    setLoading(true);
    setError(null);
    const startRank = (page - 1) * pageSize + 1;
    const endRank = page * pageSize;
    const signedInPlayerId = playerAuth?.id;

    try {
      const response = await getOrdinalRankings(startRank, endRank, rankType, signedInPlayerId);
      setTotalCount(response.totalCount);
      setTotalPages(Math.ceil(response.totalCount / pageSize));
      setRankings(response.rankings);
    } catch (err) {
      console.error('Error fetching rankings:', err);
      setError('Error al obtener los rankings.');
    } finally {
      setLoading(false);
    }
  }, [rankType, playerAuth?.id]);

  // Load initial rankings based on initialPage
  useEffect(() => {
    setCurrentPage(initialPage);
    loadRankings(initialPage);
  }, [initialPage, loadRankings]);

  // Handle Previous Page
  const handlePrevious = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      loadRankings(newPage);
    }
  };

  // Handle Next Page
  const handleNext = () => {
    if (currentPage * pageSize < totalCount) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      loadRankings(newPage);
    }
  };

  if (error) return <p>{error}</p>;

  return (
    <div className="ranking-table-container">
      <h2 className="ranking-heading">{translateRankingType(rankType)}</h2>
      <div className="ranking-table-wrapper">
        <table className="ranking-table">
          <thead>
            <tr>
              <th className='column-centered'>{translateRankingTypeRaw(rankType)}</th>
              <th className='column-centered'>Jugador</th>
              <th className='column-centered'><strong>Nivel</strong> <span className='confiabilidad-span-th'>(% Confiabilidad)</span></th>
              <th className='mobile-hide column-centered'>{translateRankingType(otherRankTypes[0])}</th>
              <th className='mobile-hide column-centered'>{translateRankingType(otherRankTypes[1])}</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              // Render empty rows with placeholders while loading
              Array.from({ length: pageSize }).map((_, index) => (
                <tr key={index}>
                  <td className='column-centered'>...</td>
                  <td className='column-centered'>...</td>
                  <td className='column-centered'>...</td>
                  <td className='mobile-hide column-centered'>...</td>
                  <td className='mobile-hide column-centered'>...</td>
                </tr>
              ))
            ) : (
              rankings.map((ranking) => (
                <tr
                  key={ranking.playerId}
                  className={playerAuth && ranking.playerId === playerAuth.id ? 'highlight-row' : ''}
                >
                  <td className='column-centered'>{ranking.selectedOrdinalRank}</td>
                  <td className='column-centered player-name-containertd'>
                    <div className="ranking-player-name-box" title={ranking.isChallengeable ? `Desafiar a ${ranking.playerName}` : ''}>
                      <Link to={`/players/${ranking.playerId}`} style={{ display: 'contents' }}>
                        <p>{ranking.playerName}</p>
                      </Link>
                      {ranking.isChallengeable && (
                        <Link to={`/create-challenge?challengedId=${ranking.playerId}&rankingType=${rankType}`} style={{ display: 'contents' }}>
                          <div className="challenge-icon">
                            {isMobile ? 'D' : 'Desafiar'}
                          </div>
                        </Link>
                      )}
                    </div>
                  </td>
                  <td className='column-centered elo-rating-ordinal-table'>
                    {ranking.dynamicLevel.toFixed(2)} {ranking.rankingReliability !== undefined ? <span>{`(${(ranking.rankingReliability * 100).toFixed(0)}%)`}</span> : 'N/A'}
                  </td>
                  <td className='mobile-hide column-centered'>
                    {
                      otherRankTypes[0] === 'absoluteOrdinalRanking' ? ((ranking.absoluteOrdinalRank > 0) ? ranking.absoluteOrdinalRank : '-') :
                        otherRankTypes[0] === 'age45OrdinalRanking' ? ((ranking.age45OrdinalRank > 0) ? ranking.age45OrdinalRank : '-') :
                          (ranking.age55OrdinalRank > 0) ? ranking.age55OrdinalRank : '-'
                    }
                  </td>
                  <td className='mobile-hide column-centered'>
                    {
                      otherRankTypes[1] === 'absoluteOrdinalRanking' ? ((ranking.absoluteOrdinalRank > 0) ? ranking.absoluteOrdinalRank : '-') :
                        otherRankTypes[1] === 'age45OrdinalRanking' ? ((ranking.age45OrdinalRank > 0) ? ranking.age45OrdinalRank : '-') :
                          (ranking.age55OrdinalRank > 0) ? ranking.age55OrdinalRank : '-'
                    }
                  </td>
                </tr>
              )))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={handlePrevious} disabled={currentPage === 1}>
          Anterior
        </button>
        <span className="pagination-info">
          Página {currentPage} de {totalPages}
        </span>
        <button onClick={handleNext} disabled={currentPage * pageSize >= totalCount}>
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default RankingTable;
